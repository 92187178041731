import React from 'react';

import Spinner from '@components/common/Spinner/Spinner';
import { AvatarType } from '@propTypes/userTypes';

import css from './CircleAvatar.module.scss';

type CircleAvatarProps = {
    width?: string;
    height?: string;
    avatar: AvatarType;
    customClass?: string;
};

const CircleAvatar = ({ width, height, avatar, customClass }: CircleAvatarProps) => {
    const style = {
        backgroundImage: `url(${avatar?.src})`,
        backgroundSize: avatar?.isEmoji ? 'auto' : 'cover',
        width: width || '38px',
        height: height || '38px',
    };

    return (
        <div>
            <div className={`${css.avatar} ${customClass || ''}`} style={style}>
                {!avatar && <Spinner />}
            </div>
        </div>
    );
};
export default CircleAvatar;
