import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';
import CircleAvatar from '@components/lpc/Navbar/CircleAvatar/CircleAvatar';
import { AvatarType } from '@propTypes/userTypes';
import { ROUTE } from '@services/http/Route';

import css from './Profile.module.scss';

type ProfileProps = {
    firstName: string;
    email: string;
    isOpen: boolean;
    onSignOut: () => void;
    avatar: AvatarType;
};

const Profile = ({ onSignOut, firstName, isOpen, avatar, email }: ProfileProps) => {
    const { t } = useTranslateLpc('lpc-navbar');
    const router = useRouter();

    return (
        <div className={`${css.container} ${isOpen && css.container__open}`}>
            <div className={css.profile} onClick={() => router.push(ROUTE.LPC.DASHBOARD.MY_PROFILE)}>
                <CircleAvatar avatar={avatar} width={'48px'} height={'48px'} />
                <div className={css.profile__infos}>
                    <span className={css.profile__infos__name}>{firstName}</span>
                </div>
            </div>
            <Text variant={'caption_02'} customClass={css.email} color={'txt-primary'}>
                {email}
            </Text>
            <div className={css.divider} />
            <Link href={ROUTE.LPC.DASHBOARD.MY_POTS_CREATED} className={css.cta}>
                <img src="/front-static/icons/action/prepaiement.png" height={24} alt="link" />
                <p>{t('account.pots')}</p>
            </Link>
            <Link href={ROUTE.LPC.DASHBOARD.MY_PARAMS} className={css.cta}>
                <img src="/front-static/icons/action/view-grid.svg" alt="link" />
                <p>{t('account.dashboard')}</p>
            </Link>
            <span onClick={() => onSignOut()} className={css.cta}>
                <img src="/front-static/icons/action/log-out.svg" alt="logout" />
                <p>{t('account.logout')}</p>
            </span>
        </div>
    );
};

export default Profile;
