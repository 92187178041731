import React, { useEffect, useState } from 'react';

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import NavMobileCollapsible from '@components/common/Collapsible/NavMobileCollapsible/NavMobileCollapsible';
import NavMobileLink from '@components/common/Collapsible/NavMobileLink/NavMobileLink';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import PdfGenerator from '@components/lpc/Navbar/PdfGenerator/PdfGenerator';
import Participate from '@components/lpc/Pot/AmountModule/Participate/Participate';
import { personRoles } from '@models/PotModel/personRoles';
import { statusPO } from '@models/PotModel/statusPO';
import { poolContributionType } from '@propTypes/potTypes';
import { principalType } from '@propTypes/userTypes';
import greetingCardService from '@services/domain/Lpc/GreetingCardService';
import { getRoute, ROUTE } from '@services/http/Route';
import ObjectService from '@services/utils/ObjectService';

import css from './NavMobile.module.scss';

type NavMobileProps = {
    principal?: principalType;
    personRole?: string;
    potRef?: string;
    poolContribution?: poolContributionType;
    isOffered?: boolean;
    potStatus?: string;
    hideButtons?: boolean;
    showParticipate?: boolean;
    participateDisabled?: boolean;
    isGreetingCardPage?: boolean;
    potTitle?: string;
    potCategory?: string;
    setOpenParticipate?: (boolean) => void;
};

const NavMobile = ({
    principal,
    personRole,
    potRef,
    poolContribution,
    isOffered,
    potStatus,
    hideButtons,
    showParticipate,
    isGreetingCardPage,
    potTitle,
    potCategory,
    participateDisabled,
    setOpenParticipate,
}: NavMobileProps) => {
    const { t } = useTranslateLpc(['lpc-navbar', 'common', 'lpc-pot']);
    const router = useRouter();
    const { addToast } = useToasts();

    const [isOpen, setIsOpen] = useState(false);

    const [navLink, setNavLink] = useState([
        {
            title: t('help-center'),
            link: ROUTE.LPC.HELP_CENTER.DETAILS,
            rows: [],
        },
        {
            title: t('select.placeholder'),
            link: '',
            rows: [
                {
                    row: t('select.partners'),
                    link: ROUTE.LPC.PURCHASING_TYPE.PARTNERS_NETWORKS,
                    icon: '/front-static/icons/navigation/chevron-right.svg',
                },
                {
                    row: t('select.bank-transfer'),
                    link: ROUTE.LPC.PURCHASING_TYPE.BANK_TRANSFER,
                    icon: '/front-static/icons/navigation/chevron-right.svg',
                },
            ],
        },
        {
            title: t('account.placeholder'),
            link: '',
            rows: [
                {
                    row: t('account.pots'),
                    link: ROUTE.LPC.DASHBOARD.MY_POTS_CREATED,
                    icon: '/front-static/icons/navigation/chevron-right.svg',
                },
                {
                    row: t('account.dashboard'),
                    link: ROUTE.LPC.DASHBOARD.MY_PARAMS,
                    icon: '/front-static/icons/navigation/chevron-right.svg',
                },
                {
                    row: t('account.logout'),
                    icon: '/front-static/icons/navigation/chevron-right.svg',
                    action: 'logout',
                },
            ],
        },
    ]);

    const subtitle =
        potCategory !== 'BEREAVEMENT' && potCategory !== 'SOLIDARITY_POOL'
            ? t('greeting-card.title')
            : t('greeting-card.title-bereavement');

    useEffect(() => {
        !principal && setNavLink(navLink.slice(0, 1));
    }, [principal]);

    const onMenuClick = () => setIsOpen(!isOpen);

    const generatePdfDocument = async () => {
        const messageList = await greetingCardService.getAllMessages(potRef, 0, 500).catch((err) =>
            addToast(t(`errors.${err.code}`), {
                appearance: 'error',
                autoDismiss: true,
            }),
        );
        const blob = await pdf(
            <PdfGenerator subtitle={subtitle} potTitle={potTitle} messageList={messageList} />,
        ).toBlob();
        saveAs(blob, potCategory !== 'BEREAVEMENT' ? 'LPC_carte_de_voeux' : 'LPC_carte_de_soutien');
    };

    return (
        <div>
            <nav className={css.navbar}>
                <section>
                    <Link href={getRoute(ROUTE.LPC.HOME)}>
                        <img src="/front-static/icons/logo/lpc-with-txt.svg" alt={'Le pot commun'} />
                    </Link>
                </section>

                {isGreetingCardPage && (
                    <>
                        <button className={css.navbar__right__download} onClick={() => generatePdfDocument()}>
                            <img src="/front-static/icons/action/download-outline.svg" alt="download" />
                        </button>
                    </>
                )}
                <div className={css.navbar__right}>
                    {hideButtons && (
                        <>
                            {personRole !== personRoles.BENEFICIARY && showParticipate && (
                                <>
                                    {!isOffered && potStatus === statusPO.RUNNING ? (
                                        <Participate
                                            isFrozen={participateDisabled}
                                            customCta={css.navbar__right__cta}
                                            customVariant={'primary'}
                                            potRef={potRef}
                                            poolContribution={poolContribution}
                                            personRole={personRole}
                                            setOpenParticipate={(value) => {
                                                setOpenParticipate(value);
                                                onMenuClick();
                                            }}
                                        />
                                    ) : (
                                        <Link href={getRoute(ROUTE.LPC.POT.CREATE_POT)}>
                                            <Button customClass={css.navbar__right__cta} variant={'primary'}>
                                                {t('create-pot')}
                                            </Button>
                                        </Link>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <section onClick={() => onMenuClick()} className={css.navbar__right____menu}>
                        <div className={isOpen ? css.navbar__right__menu__open : css.navbar__right__menu__close} />
                    </section>
                </div>
            </nav>

            <section className={`${css.sidebar} ${isOpen ? css.sidebar__open : css.sidebar__close}`}>
                {navLink.map((category) =>
                    !ObjectService.hasContent(category.rows) ? (
                        <NavMobileLink
                            key={category.title}
                            title={category.title}
                            link={category.link}
                            customTitle={css.collapsible__trigger}
                        />
                    ) : (
                        <NavMobileCollapsible
                            elementId={category.title}
                            key={category.title}
                            trigger={category.title}
                            content={category.rows.map((row) => row)}
                            customCollapsible={css.collapsible}
                            customTrigger={css.collapsible__trigger}
                            customTriggerOpened={css.collapsible__trigger__opened}
                            customText={css.collapsible__text}
                            customTriggerOuter={css.collapsible__outer}
                            customTriggerInner={css.collapsible__inner}
                        />
                    ),
                )}

                <div className={css.sidebar__btn}>
                    {!principal && (
                        <Link href={getRoute(ROUTE.LPC.AUTH.SIGN_IN, `?source=`, router.asPath)}>
                            <Button customClass={css.sidebar__btn__cta} variant={'white'}>
                                {t('sign-in')}
                            </Button>
                        </Link>
                    )}
                    {!hideButtons && (
                        <>
                            <Link href={getRoute(ROUTE.LPC.POT.CREATE_POT)}>
                                <Button customClass={css.sidebar__btn__cta} variant={'primary'}>
                                    {t('create-pot')}
                                </Button>
                            </Link>
                        </>
                    )}
                </div>
            </section>
        </div>
    );
};

export default NavMobile;
