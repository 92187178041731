import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import Collapsible from 'react-collapsible';

import Text from '@components/common/Text/Text';
import AuthService from '@services/domain/Lpc/AuthService';
import { getRoute, ROUTE } from '@services/http/Route';
import { clearStorage } from '@services/utils/StorageService';

import css from './NavMobileCollapsible.module.scss';

type CustomCollapsibleProps = {
    trigger: string;
    content: { row: string; link?: string; icon?: string; action?: string }[];
    customCollapsible?: string;
    customTrigger?: string;
    customTriggerOpened?: string;
    customTriggerInner?: string;
    customTriggerOuter?: string;
    customText?: string;
    elementId: string;
};

const NavMobileCollapsible = ({
    trigger,
    content,
    customCollapsible,
    customTrigger,
    customTriggerOpened,
    customTriggerInner,
    customTriggerOuter,
    customText,
    elementId,
}: CustomCollapsibleProps) => {
    const router = useRouter();

    const onLogout = () => {
        AuthService.signOut().then(() => {
            clearStorage('session');
            router.push(getRoute(ROUTE.LPC.AUTH.SIGN_IN));
        });
    };

    const actions = {
        logout: onLogout,
    };

    return (
        <>
            <Collapsible
                classParentString={`${css.collapsible} ${customCollapsible || ''}`}
                trigger={trigger}
                triggerClassName={`${css.trigger} ${customTrigger || ''}`}
                triggerOpenedClassName={`${css.trigger__opened} ${customTriggerOpened || ''}`}
                contentInnerClassName={`${css.content__inner} ${customTriggerInner || ''}`}
                contentOuterClassName={`${css.content__outer} ${customTriggerOuter || ''}`}
                contentElementId={`collapsible-content-${elementId}`}
                triggerElementProps={{
                    id: `collapsible-trigger-${elementId}`,
                }}
                triggerTagName={'div'}
                easing={'ease-in-out'}
            >
                {content.map((item) =>
                    item.link ? (
                        <Link key={item.row} href={item.link} className={css.content__inner}>
                            <Text customClass={customText || ''} variant={'body_02'} color={'txt-secondary'}>
                                {item.row}
                                {item.icon && <img className={css.content__icon} src={item.icon} alt="link" />}
                            </Text>
                        </Link>
                    ) : (
                        <div key={item.row} onClick={() => actions[item.action]()} className={css.content__inner}>
                            <Text customClass={customText || ''} variant={'body_02'} color={'txt-secondary'}>
                                {item.row}
                                {item.icon && <img className={css.content__icon} src={item.icon} alt="link" />}
                            </Text>
                        </div>
                    ),
                )}
            </Collapsible>
        </>
    );
};

export default NavMobileCollapsible;
