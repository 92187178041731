import React, { useRef, useState } from 'react';

import Link from 'next/link';

import useOnClickOutside from '@components/common/hooks/useOnClickOutside';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './SpendingWayDropdown.module.scss';
const SpendingWayDropdown = () => {
    const { t } = useTranslateLpc(['lpc-navbar', 'lpc-home']);

    const dropdownRef = useRef();
    useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <>
            <div ref={dropdownRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)} className={css.select}>
                <Text variant={'caption_01'} color={'txt-primary'}>
                    {t('select.placeholder')}
                </Text>
                <img
                    className={isDropdownOpen ? css.select__arrow_open : css.select__arrow_closed}
                    src={'/front-static/icons/navigation/caret-down.svg'}
                    alt="open"
                />
            </div>
            <div className={`${css.container} ${isDropdownOpen && css.container_open}`}>
                <div className={`${css.card} ${css.card__free}`}>
                    <div className={css.card__label}>
                        <Text variant={'caption_02'} color={'white'}>
                            {t('payment-options.ffa')}
                        </Text>
                    </div>

                    <Link href={getRoute(ROUTE.LPC.PURCHASING_TYPE.PARTNERS_NETWORKS)} className={css.card__free__row}>
                        <figure className={css.icon}>
                            <img src="/front-static/icons/home/dropdown/icon-1.svg" alt="coin" />
                        </figure>
                        <Text customClass={css.card__txt} variant={'caption_00'} color={'txt-primary'}>
                            {t('payment-options.card.partners.title')}
                        </Text>
                    </Link>
                </div>

                <Link href={getRoute(ROUTE.LPC.PURCHASING_TYPE.BANK_TRANSFER)}>
                    <div className={`${css.card} ${css.card__comm}`}>
                        <div className={css.card__comm__content}>
                            <div className={css.card__label}>
                                <Text variant={'caption_02'} color={'white'}>
                                    {t('payment-options.comm')}
                                </Text>
                            </div>
                            <figure className={css.icon}>
                                <img src="/front-static/icons/home/dropdown/icon-3.svg" alt="coin" />
                            </figure>
                            <Text variant={'caption_00'} color={'txt-primary'}>
                                {t('payment-options.card.bank-transfer.title')}
                            </Text>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default SpendingWayDropdown;
