import React from 'react';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import { CookiesType } from '@propTypes/cookiesType';

import css from './CookiesChoicesButtons.module.scss';

type PersonalizeCookiesModalProps = {
    onSendConsentCookie: (cookiesChoices: CookiesType) => void;
    customClass: string;
};
const CookiesChoicesButtons = ({ onSendConsentCookie, customClass }: PersonalizeCookiesModalProps) => {
    const { t } = useTranslateLpc('lpc-cookie');

    return (
        <div className={css.btn_container}>
            <Button
                margin={'0 8px 8px 0'}
                variant={'white'}
                width={'118px'}
                onClick={() => onSendConsentCookie({ functional: true })}
                customClass={`${css.btn} ${customClass}`}
            >
                {t(`personalize-cookies-modal.refuse`)}
            </Button>

            <Button
                margin={'0 0 8px 0'}
                variant={'white'}
                width={'133px'}
                onClick={() =>
                    onSendConsentCookie({ functional: true, analytics: true, social: true, technical: true })
                }
                customClass={`${css.btn} ${customClass}`}
            >
                {t(`personalize-cookies-modal.accept`)}
            </Button>
        </div>
    );
};

export default CookiesChoicesButtons;
