import React, { useState } from 'react';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Switch from '@components/common/Switch/Switch';
import Text from '@components/common/Text/Text';

import css from './CookieChoice.module.scss';

type CookieChoiceProps = {
    isActive: boolean;
    title: string;
    text: string;
    isMandatory: boolean;
    onChange: (bool: boolean) => void;
};
const CookieChoice = ({ isActive, text, title, onChange, isMandatory }: CookieChoiceProps) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const { t } = useTranslateLpc('lpc-cookie');

    return (
        <>
            <div className={`${css.header} ${isMandatory ? css.first_component : ''}`}>
                <div className={css.header__name} onClick={() => setShowDetails(!showDetails)}>
                    <img
                        src={`/front-static/icons/action/navigation-caret-${showDetails ? 'up' : 'down'}.svg`}
                        alt={`${showDetails ? 'hide' : 'show'}`}
                    />
                    <Text variant={'caption_00'} customClass={css.modal__title} color={'txt-primary'}>
                        {title}
                    </Text>
                </div>

                {isMandatory ? (
                    <Text variant={'caption_01'} color={'txt-secondary'}>
                        {t(`personalize-cookies-modal.mandatory`)}
                    </Text>
                ) : (
                    <div>
                        <Switch defaultOn={isActive} onChange={(bool) => onChange(bool)} />
                    </div>
                )}
            </div>

            {showDetails && (
                <div>
                    <Text variant={'body_02'} customClass={css.desc} color={'txt-primary'}>
                        {text}
                    </Text>
                </div>
            )}
        </>
    );
};

export default CookieChoice;
