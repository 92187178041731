import React from 'react';

import Link from 'next/link';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import Text from '@components/common/Text/Text';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './ConfirmKycModal.module.scss';

type ConfirmKycModalProps = {
    firstName: string;
    lastName: string;
    birthday: string;
    category: string;
    isVisible: boolean;
    isLoading?: boolean;
    onConfirm?: () => void;
};

const ConfirmKycModal = ({
    isVisible,
    firstName,
    lastName,
    birthday,
    category,
    onConfirm,
    isLoading,
}: ConfirmKycModalProps) => {
    const { t } = useTranslateLpc('common');

    return (
        <Modal visible={isVisible} hideCloseButton={true} onHide={() => {}} customClass={css.custom_relays}>
            <div className={css.modal}>
                <h4>{t(`kyc-popup.title`)}</h4>
                <Text customClass={css.modal__text} variant={'body_02'} color={'ui-primary'}>
                    {t(`kyc-popup.firstName`) + firstName}
                </Text>
                <Text customClass={css.modal__text} variant={'body_02'} color={'ui-primary'}>
                    {t(`kyc-popup.lastName`) + lastName}
                </Text>
                <Text customClass={css.modal__text} variant={'body_02'} color={'ui-primary'}>
                    {t(`kyc-popup.birthday`) + birthday}
                </Text>
                <Text customClass={css.modal__lastText} variant={'body_02'} color={'ui-primary'}>
                    {t(`kyc-popup.category`) + category}
                </Text>
                <div className={css.modal__btn__container}>
                    <Button
                        margin={'0 0 8px 0'}
                        variant={'secondary'}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        onClick={() => onConfirm()}
                        customClass={css.customConfirmButton}
                    >
                        {!isLoading && t(`kyc-popup.cta-confirm`)}
                    </Button>
                    <Link href={getRoute(ROUTE.LPC.DASHBOARD.MY_KYC)} legacyBehavior>
                        <Button variant={'primary'} customClass={css.customCancelButton}>
                            {t(`kyc-popup.cta-cancel`)}
                        </Button>
                    </Link>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmKycModal;
