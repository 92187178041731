import React, { useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import Text from '@components/common/Text/Text';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './RedirectToTfaActivationModal.module.scss';

const RedirectToTfaActivationModal = () => {
    const { t } = useTranslateLpc('lpc-double-authentication');
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const router = useRouter();
    const onHide = () => {
        setIsVisible(false);
    };

    return (
        <Modal
            visible={isVisible}
            onHide={() => onHide()}
            customClass={css.required_tfa_modal}
            hideCloseButton={true}
            closeOnOverlayClick={false}
        >
            <img src={'/front-static/images/authentication/padlock.png'} alt={'padlock'} />
            <h5>{t('redirect-to-tfa-activation-modal.title')}</h5>
            <div className={css.required_tfa_modal__container}>
                <Text variant={'body_02'} color={'txt-primary'}>
                    {t('redirect-to-tfa-activation-modal.text-1')}
                </Text>
                <Text variant={'body_02'} color={'txt-primary'}>
                    {t('redirect-to-tfa-activation-modal.step-1')}
                </Text>
                <Text variant={'body_02'} color={'txt-primary'}>
                    {t('redirect-to-tfa-activation-modal.step-2')}
                </Text>
                <Text variant={'body_02'} color={'txt-primary'}>
                    {t('redirect-to-tfa-activation-modal.text-2')}
                </Text>
            </div>
            <Link href={getRoute(ROUTE.LPC.AUTH.TFA_UPDATE, `?source=`, router.asPath)}>
                <Button variant={'primary'} customClass={css.return_button}>
                    {t('redirect-to-tfa-activation-modal.next')}
                </Button>
            </Link>
        </Modal>
    );
};
export default RedirectToTfaActivationModal;
