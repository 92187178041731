import React from 'react';

import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import parse, { domToReact } from 'html-react-parser';

import { greetingCardType } from '@propTypes/potTypes';
import { encodeImage } from '@services/utils/StringService';
import { getAvatarSrcForPdf } from '@services/utils/UserService';

type PdfGeneratorProps = {
    messageList: greetingCardType[];
    potTitle: string;
    subtitle: string;
};
const options = {
    replace: (domNode) => {
        if (domNode.type === 'tag') {
            switch (domNode.name) {
                case 'b':
                    return <Text style={{ fontWeight: 'bold' }}>{domToReact(domNode.children, options)}</Text>;
                case 'i':
                    return <Text style={{ fontStyle: 'italic' }}>{domToReact(domNode.children, options)}</Text>;
                case 'u':
                    return <Text style={{ textDecoration: 'underline' }}>{domToReact(domNode.children, options)}</Text>;
                case 'img':
                    return;
                default:
                    return <Text>{domToReact(domNode.children, options)}</Text>;
            }
        }
        if (domNode.type === 'text') {
            return <Text>{domNode.data}</Text>;
        }
    },
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 50,
        backgroundColor: '#f2f7fc',
    },
    header: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 97,
        paddingRight: 97,
    },
    logo: {
        width: 28,
        height: 18,
        marginBottom: 16,
    },
    subtitle: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 12,
        color: '#e22b76',
    },
    title: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 24,
        color: '#232e69',
        marginBottom: 65,
    },
    card: {
        marginBottom: 16,
        padding: 16,
        borderRadius: 8,
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#eff4fa',
        maxWidth: '100%',
    },
    text: {
        fontFamily: 'Poppins',
        fontSize: 12,
        color: '#232e69',
    },
    user: {
        flexDirection: 'row',
        marginTop: 12,
        alignItems: 'center',
    },
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        marginRight: 4,
        borderRadius: '100%',
        backgroundColor: '#EFF4FA',
    },
    avatar: {
        flex: 1,
        resizeMode: 'auto',
    },
    image: {
        width: '200px',
    },
});

const PdfGenerator = ({ messageList, potTitle, subtitle }: PdfGeneratorProps) => {
    const extractImageSources = (htmlString: string): string[] => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const imgElements = doc.querySelectorAll('img');
        if (!imgElements) {
            return [];
        }
        const sources: string[] = [];
        imgElements.forEach((imgElement) => {
            const src = imgElement.getAttribute('src');
            if (src) {
                sources.push(src);
            }
        });
        return sources;
    };

    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
            },
            {
                src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
                fontWeight: 500,
            },
            {
                src: 'https://fonts.gstatic.com/s/poppins/v1/8JitanEsk5aDh7mDYs-fYfesZW2xOQ-xsNqO47m55DA.ttf',
                fontWeight: 600,
            },
        ],
    });
    const renderMessage = (message) => {
        return <View style={styles.text}>{parse(message, options)}</View>;
    };

    return (
        <Document>
            <Page size="A4" wrap style={styles.page}>
                <View style={styles.header}>
                    <Image style={styles.logo} source={encodeImage('/front-static/icons/logo/lpc.png')} />
                    <Text style={styles.subtitle}>{subtitle}</Text>
                    <Text style={styles.title}>{potTitle}</Text>
                </View>
                <View>
                    {messageList &&
                        messageList.length > 0 &&
                        messageList.map((item, index) => (
                            <View wrap={false} key={item.inviteID} style={styles.card}>
                                <React.Fragment key={index}>{renderMessage(item.message)}</React.Fragment>
                                <View>
                                    {item.message &&
                                        extractImageSources(item.message).map((src, index) => (
                                            <Image key={index} style={styles.image} source={src} />
                                        ))}
                                </View>
                                <View style={styles.user}>
                                    <View style={styles.circle}>
                                        <Image style={styles.avatar} source={getAvatarSrcForPdf(item).src} />
                                    </View>
                                    <Text style={styles.text}>{item.signature}</Text>
                                </View>
                            </View>
                        ))}
                </View>
            </Page>
        </Document>
    );
};

export default PdfGenerator;
