import React from 'react';

import Link from 'next/link';

import Text from '@components/common/Text/Text';

import css from './NavMobileLink.module.scss';

type NavMobileLinkProps = {
    title: string;
    link: string;
    customTitle?: string;
};

const NavMobileLink = ({ title, link, customTitle }: NavMobileLinkProps) => {
    return (
        <div className={css.help_title}>
            <Link href={link} className={css.help_title__inner}>
                <Text
                    customClass={`${css.help_title__content} ${customTitle || ''}`}
                    variant={'body_02'}
                    color={'txt-secondary'}
                >
                    {title}
                </Text>
            </Link>
        </div>
    );
};

export default NavMobileLink;
