import React, { useMemo } from 'react';

import DOMPurify from 'isomorphic-dompurify';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';

import css from './Hint.module.scss';

const Hint = () => {
    const { t } = useTranslateLpc('lpc-footer');
    const currentYear = useMemo(() => {
        return new Date().getFullYear();
    }, []);

    return (
        <div className={css.hint}>
            <Text variant={'footer'} color={'txt-secondary'}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            t('hint', {
                                currentYear: currentYear,
                                onlinePot: 'https://www.lepotcommun.fr/cagnotte-en-ligne',
                                collectMoney: 'https://www.lepotcommun.fr/collecte-d-argent-en-ligne',
                                farewellPot: 'https://www.lepotcommun.fr/idee-pot-depart-cagnotte',
                                birthdayPot: 'https://www.lepotcommun.fr/cagnotte-en-ligne-anniversaire',
                                why: 'https://www.lepotcommun.fr/pourquoi',
                            }),
                            { ADD_ATTR: ['target'] },
                        ),
                    }}
                />
            </Text>
        </div>
    );
};

export default Hint;
