import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import CustomDatePicker from '@components/common/CustomDatePicker/CustomDatePicker';
import Select from '@components/common/Dropdowns/Select/Select';
import Checkbox from '@components/common/Formik/FormikCheckBox';
import Input from '@components/common/Formik/FormikInputField';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import { missingInformationsType } from '@propTypes/userTypes';
import AuthService from '@services/domain/Lpc/AuthService';
import { getRoute, ROUTE } from '@services/http/Route';
import { formatDate, getDurationFromTimeStamp } from '@services/utils/DateService';
import { getItemFromStorage } from '@services/utils/StorageService';

import css from './MissingInformationsModal.module.scss';

const ValidateMissingInformationsForm = () => {
    const { t } = useTranslateLpc(['lpc-pot', 'lpc-create-pot', 'lpc-authentication', 'common']);
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [ageValidationError, setAgeValidationError] = useState<string>(null);
    const [missingInformationsUser, setMissingInformationsUser] = useState<missingInformationsType>(undefined);
    const [selectedCsp, setSelectedCsp] = useState<string>('Agriculteurs sur petite ou moyenne exploitation');
    const [chosenCspCode, setChosenCspCode] = useState<string>('11');
    const [cspSecondGroups, setCspSecondGroups] = useState<any[]>(
        Array.from({ length: parseInt(t(`sign-up.step-2.csp-second-group-1.array-size`)) }, (_, index) => {
            return {
                code: t(`sign-up.step-2.csp-second-group-1.${index}.code`).toString(),
                name: t(`sign-up.step-2.csp-second-group-1.${index}.name`).toString(),
            };
        }),
    );
    let cspSecondGroupsHolder: { code: string; name: string }[];
    const cspFirstgroups = Array.from(
        { length: parseInt(t(`sign-up.step-2.csp-first-groups.array-size`)) },
        (_, index) => {
            return {
                id: t(`sign-up.step-2.csp-first-groups.${index + 1}.id`).toString(),
                name: t(`sign-up.step-2.csp-first-groups.${index + 1}.name`).toString(),
            };
        },
    );
    const router = useRouter();
    useEffect(() => {
        setMissingInformationsUser(AuthService.getMissingInformationsUser());
    }, []);
    const now = new Date().getUTCFullYear();
    const years = Array(115)
        .fill('')
        .map((_, index) => now - index);
    const getInitialValues = () => {
        return {
            emailPE: null,
            birthDayPE: null,
            acceptNewsLetterPE: null,
            acceptCommercialsPE: null,
        };
    };

    const onHide = () => {
        setIsVisible(false);
    };

    const close = () => {
        onHide();
        AuthService.deleteMissingInformationsCookie();
    };

    const onSuccess = async () => {
        setIsVisible(false);
        addToast(t(`success.UPDATE_SUCCESS`), {
            appearance: 'success',
            autoDismiss: true,
        });
        if (getItemFromStorage('session', 'temporaryPot')) {
            return router.push(getRoute(ROUTE.LPC.POT.CREATE_POT)).then();
        }
        if (router.query?.potRef) {
            return router.push(getRoute(ROUTE.LPC.POT.POT, router.query?.potRef, `?participate=${true}`)).then();
        }
        if (getItemFromStorage('local', 'payWithPot')) {
            return router
                .push(getRoute(ROUTE.LPC.POT.SPENDING_PARTNER_LIMONETIK, getItemFromStorage('local', 'payWithPot')))
                .then();
        }
        if (router.query.urlReturn) {
            // @ts-ignore
            return window.location.assign(router.query.urlReturn);
        }
        router.replace(router.asPath);
    };

    const setSecondGroupsArray = (id) => {
        id++;
        cspSecondGroupsHolder = Array.from(
            { length: parseInt(t(`sign-up.step-2.csp-second-group-${id}.array-size`)) },
            (_, index) => {
                return {
                    code: t(`sign-up.step-2.csp-second-group-${id}.${index}.code`).toString(),
                    name: t(`sign-up.step-2.csp-second-group-${id}.${index}.name`).toString(),
                };
            },
        );
        setCspSecondGroups(cspSecondGroupsHolder);
        setSelectedCsp(cspSecondGroupsHolder[0].name);
        setChosenCspCode(cspSecondGroupsHolder[0].code);
    };

    const onSubmit = (values) => {
        setIsLoading(true);
        const acceptNewsLetterPE =
            missingInformationsUser?.needNewsLettersConsent && values.acceptNewsLetterPE == null
                ? false
                : values.acceptNewsLetterPE;
        const acceptCommercialsPE =
            missingInformationsUser?.needCommercialConsent && values.acceptCommercialsPE == null
                ? false
                : values.acceptCommercialsPE;
        const cspCode = missingInformationsUser?.needCspCode ? chosenCspCode : null;
        if (missingInformationsUser?.needBirthday) {
            // verify birthday and block WS in case of error
            if (!verifyBirthday(values.birthDayPE, null)) {
                setIsLoading(false);
                return;
            }
        }
        const body = {
            emailPE: values.emailPE,
            acceptNewsLetterPE: acceptNewsLetterPE,
            acceptCommercialsPE: acceptCommercialsPE,
            birthDayPE: formatDate(values.birthDayPE, '/'),
            cspCodePE: cspCode,
        };
        AuthService.submitMissingInformations(body)
            .then(() => onSuccess())
            .catch(() => {
                addToast(t(`errors.NOT_ALLOWED`), {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const verifyBirthday = (value: string, setFieldValue: any): boolean => {
        const age = getDurationFromTimeStamp(value);
        setFieldValue && setFieldValue('birthDayPE', value);
        if (!age) {
            setAgeValidationError('INVALID_BIRTHDAY');
            return false;
        }
        if (age.years < 18) {
            setAgeValidationError('MIN_AGE');
            return false;
        }
        if (age.years > 115) {
            setAgeValidationError('MAX_AGE');
            return false;
        }
        setAgeValidationError(null);
        return true;
    };

    const renderForm = (errors, setFieldValue, values) => {
        return (
            <Form className={css.form}>
                <div className={css.form__content}>
                    {missingInformationsUser?.needValidEmail && (
                        <div className={css.form__input}>
                            <Input
                                name="emailPE"
                                type="email"
                                label={t('participate.email-label')}
                                placeholder={t('participate.email-placeholder')}
                                isFloatingLabel
                            />
                        </div>
                    )}
                    {missingInformationsUser?.needBirthday && (
                        <div className={css.form__input__date}>
                            <CustomDatePicker
                                years={years}
                                label={t('validate-missing-informations.birthday')}
                                placeholder={t('form.event-date-placeholder')}
                                name="birthDayPE"
                                value={values.birthDayPE}
                                handleDateChange={(name, value) => {
                                    verifyBirthday(value, setFieldValue);
                                }}
                                error={ageValidationError}
                            />
                        </div>
                    )}
                    {missingInformationsUser?.needCspCode && (
                        <div>
                            <div className={css.form__input}>
                                <div>
                                    <Select
                                        options={cspFirstgroups.map((cat: { id: string; name: string }, index) => {
                                            return {
                                                value: index.toString(),
                                                label: cat.name,
                                            };
                                        })}
                                        onSelect={(value) => {
                                            setSecondGroupsArray(value);
                                        }}
                                        customSelect={css.form__select}
                                        customMenu={css.form__select__menu}
                                        customControl={css.form__select__control}
                                        customWrapper={css.form__select__wrapper}
                                        label={t('sign-up.step-2.you-are')}
                                    />
                                </div>
                            </div>
                            <div className={css.form__input}>
                                <Select
                                    options={cspSecondGroups.map((cat: { code: string; name: string }) => {
                                        return {
                                            value: cat.code,
                                            label: cat.name,
                                        };
                                    })}
                                    onSelect={(chosenCspCode) => setChosenCspCode(chosenCspCode)}
                                    value={selectedCsp}
                                    customSelect={css.form__second__select}
                                    customMenu={css.form__select__menu}
                                    customControl={css.form__select__control}
                                    customWrapper={css.form__select__wrapper}
                                    label={t('sign-up.step-2.precise')}
                                />
                            </div>
                        </div>
                    )}
                    {missingInformationsUser?.needNewsLettersConsent && (
                        <div className={css.form__terms}>
                            <div className={css.form__checkbox}>
                                <div className={css.form__input}>
                                    <Checkbox name="acceptNewsLetterPE">{t('participate.newsletter-label')}</Checkbox>
                                </div>
                            </div>
                        </div>
                    )}
                    {missingInformationsUser?.needCommercialConsent && (
                        <div className={css.form__terms}>
                            <div className={css.form__checkbox}>
                                <div className={css.form__input}>
                                    <Checkbox name="acceptCommercialsPE">{t('participate.commercials-label')}</Checkbox>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={css.button__container}>
                        <Button
                            type={'submit'}
                            variant={'primary'}
                            customClass={css.form__submit__cta}
                            isLoading={isLoading}
                            isDisabled={Object.keys(errors).length !== 0 || isLoading || ageValidationError != null}
                        >
                            {t('validate-missing-informations.send')}
                        </Button>
                        <Button
                            type={'button'}
                            variant={'secondary'}
                            customClass={css.form__submit__cta}
                            onClick={() => close()}
                        >
                            {t('validate-missing-informations.back')}
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };
    return (
        <Modal
            visible={isVisible}
            onHide={() => onHide()}
            customClass={css.custom}
            hideCloseButton={true}
            closeOnOverlayClick={false}
        >
            <div className={css.header}>
                <h5>{t('validate-missing-informations.title')}</h5>
            </div>

            <Formik
                initialValues={getInitialValues()}
                onSubmit={(values) => onSubmit(values)}
                validateOnMount={true}
                enableReinitialize={true}
            >
                {({ errors, values, setFieldValue }) => renderForm(errors, setFieldValue, values)}
            </Formik>
        </Modal>
    );
};

export default ValidateMissingInformationsForm;
