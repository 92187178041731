import React from 'react';

import DOMPurify from 'isomorphic-dompurify';
import AwesomeModal from 'react-responsive-modal';

import css from './Modal.module.scss';

type ModalProps = {
    visible: boolean;
    onHide: () => void;
    children: React.ReactNode;
    mainTitle?: string;
    titleCustomClass?: string;
    hideCloseButton?: boolean;
    customClass?: string;
    customContainer?: string;
    customRootClass?: string;
    customOverlay?: string;
    closeOnOverlayClick?: boolean;
    showCloseIcon?: boolean;
    customCloseIconClass?: string;
    blockScroll?: boolean;
};

const Modal = ({
    visible,
    onHide,
    children,
    mainTitle,
    titleCustomClass,
    customClass,
    hideCloseButton,
    customOverlay,
    customRootClass,
    closeOnOverlayClick,
    customContainer,
    customCloseIconClass,
    blockScroll = false,
}: ModalProps) => {
    return (
        <>
            <div className={css.modal_wrapper}>
                {visible && (
                    <AwesomeModal
                        blockScroll={blockScroll}
                        closeOnOverlayClick={closeOnOverlayClick}
                        center={true}
                        classNames={{
                            root: css.root + ' ' + customRootClass,
                            overlay: css.overlay + ' ' + customOverlay,
                            modal: css.modal + ' ' + customClass,
                            modalContainer: css.modal_container + ' ' + customContainer,
                            modalAnimationIn: css.modal__transition_enter,
                            overlayAnimationIn: css.modal__transition_enter_active,
                            modalAnimationOut: css.modal__transition_exit,
                            overlayAnimationOut: css.modal__transition_exit_active,
                        }}
                        showCloseIcon={false}
                        open={visible}
                        onClose={() => onHide()}
                    >
                        <div className={css.modal__content_wrapper}>
                            {!hideCloseButton && (
                                <span
                                    onClick={() => onHide()}
                                    className={`${css.modal__close_button} ${customCloseIconClass || ''}`}
                                >
                                    <img src="/front-static/icons/navigation/close-pink.svg" alt="close" />
                                </span>
                            )}
                            {mainTitle && (
                                <h3
                                    className={titleCustomClass && titleCustomClass}
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainTitle) }}
                                />
                            )}
                            {children}
                        </div>
                    </AwesomeModal>
                )}
            </div>
        </>
    );
};

export default Modal;
