import React from 'react';

import Link from 'next/link';

import FooterCollapsible from '@components/common/Collapsible/FooterCollapsible/FooterCollapsible';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import Text from '@components/common/Text/Text';
import { M_DEVICE } from '@components/lpc/Constants';
import { EXT_LINK } from '@services/http/ExtLink';
import { ROUTE } from '@services/http/Route';

import css from './Categories.module.scss';

const Categories = () => {
    const { t } = useTranslateLpc('lpc-footer');

    const categories = [
        {
            title: t('categories.col-1.title'),
            rows: [
                {
                    openInNewTab: false,
                    row: t('categories.col-1.row-1'),
                    link: ROUTE.LPC.HELP_CENTER.DETAILS,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-1.row-2'),
                    link: ROUTE.PRESS_AREA,
                },
                {
                    openInNewTab: false,
                    row: t('categories.col-1.row-3'),
                    link: ROUTE.LPC.ACTIVITY_REPORT,
                },
            ],
        },
        {
            title: t('categories.col-2.title'),
            rows: [
                {
                    openInNewTab: true,
                    row: t('categories.col-2.row-2'),
                    link: ROUTE.SMALL_LISTS,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-2.row-3'),
                    link: EXT_LINK.CITYVENT,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-2.row-5'),
                    link: ROUTE.LPC.DONATION_CONTRACT,
                },
            ],
        },
        {
            title: t('categories.col-3.title'),
            rows: [
                {
                    openInNewTab: true,
                    row: t('categories.col-3.row-1'),
                    link: EXT_LINK.FACEBOOK,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-3.row-2'),
                    link: EXT_LINK.INSTAGRAM,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-3.row-3'),
                    link: EXT_LINK.TWITTER,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-3.row-4'),
                    link: EXT_LINK.LINKEDIN,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-3.row-5'),
                    link: EXT_LINK.TIKTOK,
                },
            ],
        },
        {
            title: t('categories.col-4.title'),
            rows: [
                {
                    openInNewTab: false,
                    row: t('categories.col-4.row-1'),
                    link: ROUTE.LPC.COOKIE,
                },
                {
                    openInNewTab: false,
                    row: t('categories.col-4.row-2'),
                    link: ROUTE.LPC.PRIVACY_POLITY,
                },
                {
                    openInNewTab: false,
                    row: t('categories.col-4.row-3'),
                    link: ROUTE.LPC.CGU,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-4.row-4'),
                    link: ROUTE.LPC.CGU_XPOLLENS,
                },
                {
                    openInNewTab: true,
                    row: t('categories.col-4.row-5'),
                    link: 'https://www.xpollens.com/protection-donnees',
                },
                {
                    openInNewTab: false,
                    row: t('categories.col-4.row-6'),
                    link: ROUTE.LPC.SECURITY,
                },
            ],
        },
    ];
    const { width } = useWindowSize();

    return (
        <>
            {width > M_DEVICE ? (
                <div className={css.container}>
                    {categories.map((category) => (
                        <div key={category.title} className={css.container__col}>
                            <Text customClass={css.container__col__title} variant={'caption_01'} color={'txt-primary'}>
                                {category.title}
                            </Text>
                            {category.rows.map((row, index) => (
                                <Link key={index} href={row.link} target={row.openInNewTab ? '_blank' : ''}>
                                    <Text variant={'body_02'} color={'txt-secondary'}>
                                        {row.row}
                                    </Text>
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                categories.map((category) => (
                    <FooterCollapsible
                        elementId={category.title}
                        key={category.title}
                        trigger={category.title}
                        content={category.rows.map((row) => row)}
                    />
                ))
            )}
        </>
    );
};

export default Categories;
