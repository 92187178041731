export const isEmpty = (object) => {
    if (!object) {
        return true;
    }
    // eslint-disable-next-line no-unreachable-loop
    for (const x in object) {
        return false;
    }
    return true;
};

export const hasContent = (object: any) => {
    if (!object) {
        return false;
    }
    let objectHasContent = false;
    Object.keys(object)?.forEach((k) => {
        if (object[k]) {
            objectHasContent = true;
        }
    });
    return objectHasContent;
};

export default {
    isEmpty,
    hasContent,
};
