import React from 'react';

import useWindowSize from '@components/common/hooks/useWindowSize';
import Spinner from '@components/common/Spinner/Spinner';
import { M_DEVICE } from '@components/lpc/Constants';

import css from './Button.module.scss';

type ButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    isDisabled?: boolean;
    isLoading?: boolean;
    customClass?: string;
    variant?: string;
    size?: string;
    mobileFullWidth?: boolean;
    margin?: string;
    width?: string;
    height?: string;
    icon?: string;
    reverseIcon?: string;
    customContentClass?: string;
    customStyle?: any;
};

const Button = ({
    children,
    type,
    variant,
    size,
    isDisabled,
    isLoading,
    onClick,
    customClass,
    mobileFullWidth,
    margin,
    width,
    height,
    icon,
    customStyle,
    reverseIcon,
    customContentClass,
}: ButtonProps) => {
    const windowSize = useWindowSize();

    const buttonStyle = () => {
        let className = '';
        className += ' ' + css[variant];
        className += ' ' + css[size];
        customClass && (className += ' ' + customClass);
        isDisabled && (className += ' ' + css.disabled);
        mobileFullWidth && windowSize.width < M_DEVICE && (className += ' ' + css.mobile_full_width);

        return className;
    };

    const style = {
        margin: margin,
        width: windowSize.width < M_DEVICE && mobileFullWidth ? '90vw' : width,
        height: height,
    };

    return (
        <button
            style={customStyle || style}
            type={type}
            disabled={isDisabled}
            className={buttonStyle()}
            onClick={onClick}
        >
            <div className={`${isLoading ? css.loading : ''} ${customContentClass || ''}`}>
                {icon && <img className={css.icon} src={icon} alt={icon} />}
                {children}
                {reverseIcon && <img className={css.reverse_icon} src={reverseIcon} alt={reverseIcon} />}
            </div>
            {isLoading && <Spinner customClass={css.spinner} />}
        </button>
    );
};

export default Button;
