import React, { useState } from 'react';

import { useTranslate } from '@tolgee/react';
// eslint-disable-next-line import/no-duplicates
import { getMonth, getYear } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import fr from 'date-fns/locale/fr';
import Head from 'next/head';
import DatePicker, { registerLocale } from 'react-datepicker';

import Button from '@components/common/Button/Button';
import InputErrorMessage from '@components/common/Formik/InputErrorMessage/InputErrorMessage';
import Text from '@components/common/Text/Text';

import css from './CustomDatePicker.module.scss';

registerLocale('fr', fr);

type CustomDatePickerProps = {
    name: string;
    value: string | Date;
    handleDateChange: (name: string, val: string) => void;
    placeholder?: string;
    minDate?: Date;
    years: number[];
    customInputClassName?: string;
    customCalendarClassName?: string;
    customDayClassName?: string;
    customWrapper?: string;
    customLabelClassName?: string;
    customLabelParentClassName?: string;
    label?: string;
    error?: string;
    readonly?: boolean;
    isFloatingLabel?: boolean;
};

const CustomDatePicker = ({
    label,
    name,
    value,
    handleDateChange,
    placeholder,
    minDate,
    customInputClassName,
    customCalendarClassName,
    customDayClassName,
    customLabelClassName,
    customLabelParentClassName,
    error,
    readonly,
    customWrapper,
    isFloatingLabel,
    years,
}: CustomDatePickerProps) => {
    const { t } = useTranslate(['common']);
    const months: string[] = Array.from({ length: 12 }, (_, index) => index + 1).map((index) =>
        t(`select.months.${index - 1}`),
    );
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
    const renderHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div
            style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'space-around',
            }}
        >
            <Button
                type={'button'}
                customClass={css.custom_btn}
                variant={'white'}
                onClick={decreaseMonth}
                isDisabled={prevMonthButtonDisabled}
            >
                {'<'}
            </Button>
            <div>
                <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <Button
                type={'button'}
                customClass={css.custom_btn}
                variant={'white'}
                onClick={increaseMonth}
                isDisabled={nextMonthButtonDisabled}
            >
                {'>'}
            </Button>
        </div>
    );
    const getStyle = () => {
        let style = isFloatingLabel ? css.label__fieldset : css.label;
        if (isFloatingLabel && (isDatePickerOpen || value)) {
            style += ' ' + css.label__fieldset__active;
        }
        return style;
    };
    return (
        <>
            <Head>
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.14.1/react-datepicker.min.css"
                />
            </Head>
            <div
                className={
                    isFloatingLabel && (isDatePickerOpen || value)
                        ? `${css.migrated_wrapper} ${css.wrapper} ${customWrapper || ''}`
                        : `${css.wrapper} ${customWrapper || ''}`
                }
            >
                {label && (
                    <label htmlFor={'datePicker'} className={`${getStyle()} ${customLabelParentClassName || ''}`}>
                        <Text variant={'caption_01'} color={'txt-primary'} customClass={customLabelClassName || ''}>
                            {label}
                        </Text>
                    </label>
                )}
                <DatePicker
                    selected={(value && new Date(value)) || null}
                    onChange={(val) => {
                        handleDateChange(name, val);
                    }}
                    onCalendarClose={() => setIsDatePickerOpen(false)}
                    onCalendarOpen={() => setIsDatePickerOpen(true)}
                    id={'datePicker'}
                    renderCustomHeader={renderHeader}
                    placeholderText={placeholder}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    minDate={minDate}
                    className={`${css.input || customInputClassName} ${
                        isFloatingLabel && value && css.input__new_style_active
                    } ${readonly && css.input__disabled}`}
                    calendarClassName={css.calendar || customCalendarClassName}
                    dayClassName={() => css.day || customDayClassName}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1).toUpperCase()}
                    readOnly={readonly}
                />

                {error && <InputErrorMessage name={'DATE'} messageCode={error} />}
            </div>
        </>
    );
};

export default CustomDatePicker;
