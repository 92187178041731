import React, { useEffect, useRef, useState } from 'react';

import useWindowSize from '@components/common/hooks/useWindowSize';
import { M_DEVICE, S_DEVICE } from '@components/lpc/Constants';
import { EXT_LINK } from '@services/http/ExtLink';

import css from './TrusBox.module.scss';

const TrustBox = () => {
    const ref = useRef(null);
    const [windowSize, setWindowSize] = useState<string>('desktop');
    const { width } = useWindowSize();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (width > M_DEVICE) {
            setWindowSize('desktop');
        } else if (width <= M_DEVICE && width > S_DEVICE) {
            setWindowSize('tablet');
        } else {
            setWindowSize('mobile');
        }
    }, [width]);

    useEffect(() => {
        // @ts-ignore
        if (window.Trustpilot) {
            // @ts-ignore
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, [windowSize]);

    return (
        <a className={css.trust} href={EXT_LINK.TRUSPILOT} target="_blank" rel="noreferrer">
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="fr-FR"
                data-template-id={width > M_DEVICE ? '5406e65db0d04a09e042d5fc' : '5419b6ffb0d04a076446a9af'}
                data-businessunit-id="5197a5f90000640005346d15"
                data-style-height="56px"
                data-style-width="100%"
                data-theme="light"
            />
        </a>
    );
};
export default TrustBox;
