import React, { useRef, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from '@components/common/Button/Button';
import useOnClickOutside from '@components/common/hooks/useOnClickOutside';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import CircleAvatar from '@components/lpc/Navbar/CircleAvatar/CircleAvatar';
import Profile from '@components/lpc/Navbar/Profile/Profile';
import { principalType, userAccountType } from '@propTypes/userTypes';
import AuthService from '@services/domain/Lpc/AuthService';
import { getRoute, ROUTE } from '@services/http/Route';
import { clearStorage } from '@services/utils/StorageService';

import css from './UserOptions.module.scss';

type UserOptionsProps = {
    principal?: principalType;
    userAccount?: userAccountType;
};

const UserOptions = ({ principal, userAccount }: UserOptionsProps) => {
    const { t } = useTranslateLpc('lpc-navbar');
    const router = useRouter();

    const avatarRef = useRef();
    useOnClickOutside(avatarRef, () => setIsProfileOpen(false));

    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const onToggleProfile = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    const onSignOut = async () => {
        await AuthService.signOut();
        clearStorage('session');
        await router.push(getRoute(ROUTE.LPC.AUTH.SIGN_IN));
    };
    return (
        <>
            {principal && userAccount && (
                <div className={css.navbar} ref={avatarRef}>
                    <div className={css.avatar} onClick={() => onToggleProfile()}>
                        <CircleAvatar avatar={userAccount?.avatar} />
                    </div>
                    <Profile
                        onSignOut={() => onSignOut()}
                        isOpen={isProfileOpen}
                        email={userAccount?.email}
                        firstName={userAccount?.firstName}
                        avatar={userAccount?.avatar}
                    />
                </div>
            )}

            {principal === null && (
                <>
                    <Link href={getRoute(ROUTE.LPC.AUTH.SIGN_IN, `?source=`, router.asPath)}>
                        <Button customClass={css.navbar__right__btn} variant={'secondary'}>
                            {t('sign-in')}
                        </Button>
                    </Link>
                </>
            )}
        </>
    );
};
export default UserOptions;
