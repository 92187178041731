import ApiLpc from '@services/http/ApiLpc';
import { axiosClient, getUrl } from '@services/http/Http';

const getGreetingCardInformations = (potRef: string, ctx?: object) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.GREETING_CARD.GET_GREETING_CARD_INFORMATIONS, potRef))
        .then((res) => {
            return res.data;
        });

const update = (
    potRef: string,
    body: { disableGreetingCardPO: boolean; hasDarkThemedGreetingCard: boolean; hasAnimatedGreetingCardPO: boolean },
    ctx?: object,
) => axiosClient(ctx).put(getUrl(ApiLpc.GREETING_CARD.UPDATE, potRef), JSON.stringify(body));

const addMessage = (potRef: string, message: string, orderId: string, ctx?: object) =>
    orderId == null
        ? axiosClient(ctx).post(getUrl(ApiLpc.GREETING_CARD.ADD_MESSAGE, potRef), JSON.stringify(message))
        : axiosClient(ctx).post(getUrl(ApiLpc.POT.ADD_MESSAGE_ANONYM, potRef, orderId), JSON.stringify(message));

const getAllMessages = (potRef: string, cursor: number, pageSize: number, ctx?: string) =>
    axiosClient(ctx)
        .get(getUrl(ApiLpc.GREETING_CARD.GET_ALL_MESSAGES, potRef, cursor, pageSize))
        .then((res) => res.data);

const getMyMessage = (potRef: string, orderId?: string, ctx?: string) =>
    !orderId
        ? axiosClient(ctx)
              .get(getUrl(ApiLpc.GREETING_CARD.GET_MY_MESSAGE, potRef))
              .then((res) => res.data)
        : axiosClient(ctx)
              .get(getUrl(ApiLpc.GREETING_CARD.GET_MY_MESSAGE_BY_ORDER_ID, potRef, orderId))
              .then((res) => res.data);

const deleteMessage = (potRef: string, inviteID: number, orderId?: string, ctx?: object) =>
    orderId == null
        ? axiosClient(ctx).delete(getUrl(ApiLpc.GREETING_CARD.DELETE_MESSAGE, potRef, inviteID))
        : axiosClient(ctx).delete(getUrl(ApiLpc.GREETING_CARD.DELETE_MESSAGE_BY_ORDER_ID, potRef, orderId));

const DARK_THEME = {
    textPrimaryVariant: 'white',
    textPrimaryColor: '#FFFFFF',
    backgroundColorPrimary: '#0b0c0f',
    backgroundColorSecondary: '#191a1f',
    backgroundImage: '/front-static/images/greeting-card/dark-theme-bg.png',
};

const LIGHT_THEME = {
    textPrimaryVariant: 'txt-primary',
    textPrimaryColor: '#232e69',
    backgroundColorPrimary: '#f2f7fc',
    backgroundColorSecondary: '#FFFFFF',
    backgroundImage: '/front-static/images/greeting-card/light-theme-bg.png',
};

const greetingCardService = {
    getGreetingCardInformations,
    update,
    addMessage,
    getAllMessages,
    deleteMessage,
    getMyMessage,
    DARK_THEME,
    LIGHT_THEME,
};

export default greetingCardService;
