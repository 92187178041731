import React, { forwardRef } from 'react';

import { Tooltip as ReactTooltip } from 'react-tooltip';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';
import { personRoles } from '@models/PotModel/personRoles';
import { statusPO } from '@models/PotModel/statusPO';
import { poolContributionType } from '@propTypes/potTypes';

import css from './Participate.module.scss';

type ParticipateProps = {
    potRef: string;
    poolContribution: poolContributionType;
    customCta?: string;
    customChildren?: string;
    customVariant?: string;
    customContainer?: string;
    personRole?: string;
    commissionType?: string;
    icon?: string;
    isFrozen?: boolean;
    potStatus?: string;
    setOpenParticipate: (boolean) => void;
    isUnderlinedTextParticipate?: boolean;
    children?: React.ReactNode;
};

const Participate = forwardRef(
    (
        {
            children,
            potStatus,
            customCta,
            customChildren,
            customVariant,
            customContainer,
            isFrozen,
            personRole,
            setOpenParticipate,
            icon,
            isUnderlinedTextParticipate,
        }: ParticipateProps,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const { t } = useTranslateLpc(['lpc-pot']);

        return (
            <>
                <div ref={ref} className={customContainer || css.participate} data-tip data-tooltip-id="registerTip">
                    {isUnderlinedTextParticipate ? (
                        <span
                            onClick={() => {
                                if (!isFrozen && potStatus !== statusPO.CLOSED) {
                                    setOpenParticipate(true);
                                }
                            }}
                        >
                            <Text
                                variant={'caption_bold_medium'}
                                color={'txt-primary'}
                                customClass={isFrozen ? css.participate__cta__frozen : css.participate__cta__link}
                            >
                                {t('activity.first-participation-participate')}
                            </Text>
                        </span>
                    ) : (
                        <Button
                            onClick={() => {
                                setOpenParticipate(true);
                            }}
                            isDisabled={isFrozen || potStatus === statusPO.CLOSED}
                            variant={customVariant || 'primary'}
                            customClass={`${css.participate__cta} ${customCta || ''}`}
                            reverseIcon={icon}
                        >
                            <div className={customChildren}>
                                {t('amount.cta.participate')}
                                {children}
                            </div>
                        </Button>
                    )}

                    <ReactTooltip hidden={!isFrozen || potStatus !== statusPO.CLOSED} id="registerTip" place="top">
                        {t(
                            `header.frozen-account-${personRole === personRoles.PARTICIPANT ? 'participant' : 'organizer'}`,
                        )}
                    </ReactTooltip>
                </div>
            </>
        );
    },
);
Participate.displayName = 'Participate';
export default Participate;
