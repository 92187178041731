import React, { useEffect, useState } from 'react';

import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import PersonalizeCookiesModal from '@components/common/Modals/PersonalizeCookiesModal/PersonalizeCookiesModal';
import Text from '@components/common/Text/Text';
import { CookiesType } from '@propTypes/cookiesType';
import AuthService from '@services/domain/Lpc/AuthService';
import { AnalyticsService } from '@services/utils/AnalyticsService';

import css from './CookiesModal.module.scss';

const CookiesModal = () => {
    const [showCookiesModal, setShowCookiesModal] = useState<boolean>(false);
    const [showCookiesChoicesModal, setShowCookiesChoicesModal] = useState<boolean>(false);
    const { t } = useTranslateLpc(['lpc-cookie', 'common']);
    const { addToast } = useToasts();

    useEffect(() => {
        setShowCookiesModal(AuthService.getConsentCookie() == null);
    }, []);

    const onHide = (hideAll: boolean) => {
        setShowCookiesChoicesModal(false);
        hideAll && setShowCookiesModal(false);
    };

    const onSendConsentCookie = (cookiesChoices: CookiesType) => {
        AuthService.sendConsentCookie(cookiesChoices)
            .then((res) => res.status === 200 && setShowCookiesModal(false))
            .then(() => cookiesChoices.analytics && AnalyticsService.enableAnalyticsCookie())
            .catch(() =>
                addToast(t(`errors.DEFAULT_ERROR_MESSAGE`), {
                    appearance: 'error',
                    autoDismiss: true,
                }),
            );
    };

    return (
        <>
            <Modal
                customRootClass={css.modal__overlay}
                customOverlay={css.modal__overlay}
                hideCloseButton={true}
                closeOnOverlayClick={false}
                visible={showCookiesModal}
                onHide={() => onHide(true)}
                customClass={css.custom}
            >
                <div className={css.modal}>
                    <div className={css.modal__icon} />

                    <Text variant={'caption_00'} customClass={css.modal__title} color={'txt-primary'}>
                        {t(`cookies-modal.title`)}
                    </Text>
                    <Text htmlText={t(`cookies-modal.text`)} variant={'body_02'} color={'txt-primary'} html />

                    <div className={css.modal__btn__container}>
                        <Button
                            margin={'0 0 8px 0'}
                            variant={'secondary'}
                            onClick={() =>
                                onSendConsentCookie({
                                    functional: true,
                                    analytics: true,
                                    social: true,
                                    technical: true,
                                })
                            }
                            customClass={css.modal__btn}
                        >
                            {t(`cookies-modal.accept`)}
                        </Button>

                        <Button
                            margin={'0 0 8px 0'}
                            variant={'white'}
                            onClick={() => {
                                setShowCookiesChoicesModal(true);
                            }}
                            customClass={css.modal__btn}
                        >
                            {t(`cookies-modal.choose`)}
                        </Button>

                        <Button
                            margin={'0 0 8px 0'}
                            variant={'white'}
                            onClick={() => onSendConsentCookie({ functional: true })}
                            customClass={css.modal__btn}
                        >
                            {t(`cookies-modal.refuse`)}
                        </Button>
                    </div>
                </div>
            </Modal>
            <PersonalizeCookiesModal isVisible={showCookiesChoicesModal} onHide={(bool) => onHide(bool)} />
        </>
    );
};

export default CookiesModal;
