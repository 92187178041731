import React, { useEffect, useState } from 'react';

import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import CookiesChoicesButtons from '@components/common/Modals/PersonalizeCookiesModal/CookiesChoicesButtons/CookiesChoicesButtons';
import Text from '@components/common/Text/Text';
import CookieChoice from '@components/lpc/Cookie/CookieChoice/CookieChoice';
import { CookiesType } from '@propTypes/cookiesType';
import AuthService from '@services/domain/Lpc/AuthService';
import { AnalyticsService } from '@services/utils/AnalyticsService';

import css from './PersonalizeCookiesModal.module.scss';

type PersonalizeCookiesModalProps = {
    isVisible: boolean;
    onHide: (hideAll: boolean) => void;
};
const PersonalizeCookiesModal = ({ isVisible, onHide }: PersonalizeCookiesModalProps) => {
    const [cookiesChoices, setCookiesChoices] = useState<CookiesType>({
        functional: true,
        technical: false,
        social: false,
        analytics: false,
    });
    const { t } = useTranslateLpc(['lpc-cookie', 'common']);
    const { addToast } = useToasts();

    useEffect(() => {
        AuthService.getConsentCookie() != null && setCookiesChoices(AuthService.getConsentCookie());
    }, [isVisible]);

    const onSendConsentCookie = (cookiesChoices: CookiesType) => {
        AuthService.sendConsentCookie(cookiesChoices)
            .then((res) => res.status === 200 && onHide(true))
            .then(() => cookiesChoices.analytics && AnalyticsService.enableAnalyticsCookie())
            .catch(() =>
                addToast(t(`errors.DEFAULT_ERROR_MESSAGE`), {
                    appearance: 'error',
                    autoDismiss: true,
                }),
            );
    };

    return (
        <Modal
            customOverlay={css.customOverlay}
            customRootClass={css.customRootClass}
            customContainer={css.custom_container}
            closeOnOverlayClick={true}
            visible={isVisible}
            onHide={() => onHide(false)}
            customClass={css.custom}
        >
            <div className={css.modal}>
                <Text variant={'caption_00'} customClass={css.modal__title} color={'txt-primary'}>
                    {t(`personalize-cookies-modal.title`)}
                </Text>
                <Text variant={'body_02'} color={'txt-secondary'}>
                    {t(`personalize-cookies-modal.text`)}
                </Text>
                <div className={css.divider} />

                <CookiesChoicesButtons
                    customClass={css.modal__btn__mobile_display}
                    onSendConsentCookie={(choices) => {
                        onSendConsentCookie(choices);
                    }}
                />

                {Array.from(
                    { length: parseInt(t('personalize-cookies-modal.cookies.array-size')) },
                    (_, cookieIndex) => (
                        <div key={`cookie-${cookieIndex}`}>
                            <CookieChoice
                                isMandatory={cookieIndex === 0}
                                isActive={cookiesChoices[t(`personalize-cookies-modal.cookies.${cookieIndex}.id`)]}
                                title={t(`personalize-cookies-modal.cookies.${cookieIndex}.name`)}
                                text={t(`personalize-cookies-modal.cookies.${cookieIndex}.desc`)}
                                onChange={(bool) => {
                                    setCookiesChoices({
                                        ...cookiesChoices,
                                        [t(`personalize-cookies-modal.cookies.${cookieIndex}.id`)]: bool,
                                    });
                                }}
                            />
                            <div className={css.divider} />
                        </div>
                    ),
                )}

                <div className={css.modal__btn__container}>
                    <CookiesChoicesButtons
                        customClass={css.modal__btn__mobile_hide}
                        onSendConsentCookie={(choices) => {
                            onSendConsentCookie(choices);
                        }}
                    />
                    <Button
                        margin={'0 0 8px 0'}
                        variant={'primary'}
                        width={'179px'}
                        onClick={() => {
                            onSendConsentCookie(cookiesChoices);
                        }}
                        customClass={css.modal__btn}
                    >
                        {t(`personalize-cookies-modal.confirm`)}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default PersonalizeCookiesModal;
