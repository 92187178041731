import React from 'react';

import Text from '@components/common/Text/Text';

import css from './Switch.module.scss';

type SwitchProps = {
    customClass?: string;
    children?: React.ReactNode;
    defaultOn: boolean;
    onChange: (boolean) => void;
};

const Switch = ({ customClass, defaultOn, onChange, children, ...props }: SwitchProps) => {
    const handleClick = () => {
        onChange(!defaultOn);
    };

    return (
        <div className={`${css.cg__switch} ${customClass || ''}`} {...props}>
            <span
                className={`${css.cg__switch} ${css.cg__switch__toggle} ${defaultOn ? css.cg__switch__toggle__on : ''}`}
                onClick={() => handleClick()}
                role="button"
                aria-pressed="true"
            />
            {children && (
                <Text variant={'caption_03'} color={'txt-primary'}>
                    {children}
                </Text>
            )}
        </div>
    );
};
export default Switch;
