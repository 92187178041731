import React from 'react';

import Categories from '@components/lpc/Footer/Categories/Categories';
import Hint from '@components/lpc/Footer/Hint/Hint';
import TrustBox from '@components/lpc/TrusBox/TrustBox';

import css from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={css.footer}>
            <Categories />
            <TrustBox />
            <Hint />
        </footer>
    );
};

export default Footer;
