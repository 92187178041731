import React, { useState, useRef } from 'react';

import { ErrorMessage } from 'formik';
import Dropdown from 'react-dropdown';

import InputErrorMessage from '@components/common/Formik/InputErrorMessage/InputErrorMessage';
import useWindowSize from '@components/common/hooks/useWindowSize';
import Text from '@components/common/Text/Text';
import { M_DEVICE } from '@components/lpc/Constants';

import css from './Select.module.scss';
import useOnClickOutside from '../../hooks/useOnClickOutside';

type DropdownProps = {
    options: { value: string; label: any }[];
    customSelect?: string;
    placeholder?: any;
    closedIcon?: string;
    openIcon?: string;
    label?: string;
    onSelect?: (string) => void;
    customMenu?: string;
    customWidth?: string;
    customControl?: string;
    leftIcon?: string;
    customWrapper?: string;
    customArrowClosed?: string;
    customPlaceholder?: string;
    customLabel?: string;
    value?: string | number;
    name?: string;
    disabled?: boolean;
    isFloatingLabel?: boolean;
    isActivatedLabel?: boolean;
    isFormikComponent?: boolean;
    onlyShowPlaceholder?: boolean;
    autoScroll?: boolean;
};

const Select = ({
    options,
    customSelect,
    label,
    placeholder,
    closedIcon,
    openIcon,
    onSelect,
    customMenu,
    customControl,
    leftIcon,
    customWrapper,
    customArrowClosed,
    disabled,
    value,
    name,
    isFormikComponent,
    customPlaceholder,
    isFloatingLabel,
    customLabel,
    onlyShowPlaceholder,
    autoScroll,
    isActivatedLabel,
}: DropdownProps) => {
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef();
    const menuRef = useRef(null);
    const { width } = useWindowSize();
    useOnClickOutside(ref, () => setIsOpen(false));

    const handleChange = (e) => {
        onSelect(e.value);
        setIsOptionSelected(true);
        setIsOpen(false);
    };

    const handleFocus = () => {
        setIsOpen(true);
        if (autoScroll && menuRef.current && width <= M_DEVICE) {
            window.scrollTo({
                top: menuRef.current.dropdownRef.current.offsetParent.offsetTop - 70,
                behavior: 'smooth',
            });
        }
    };

    const arrowClosed = (
        <span
            className={
                customArrowClosed ? `${css.select__arrow_closed} ${customArrowClosed}` : css.select__arrow_closed
            }
        >
            <img src={closedIcon || '/front-static/icons/navigation/caret-down-brand-primary.svg'} alt="open" />
        </span>
    );

    const arrowOpen = (
        <span className={css.select__arrow_open}>
            <img src={openIcon || '/front-static/icons/navigation/caret-down-brand-primary.svg'} alt="close" />
        </span>
    );

    const getStyle = () => {
        let style = isFloatingLabel ? css.label__fieldset : css.label;
        if (customLabel) {
            style += ' ' + customLabel;
        }
        if ((value || isActivatedLabel) && isFloatingLabel) {
            style += ' ' + css.label__fieldset__active;
        }

        return style;
    };

    return (
        <div className={`${css.wrapper} ${customWrapper || ''}`} ref={ref}>
            {label && (
                <div className={getStyle()}>
                    <Text variant={'caption_01'} color={'txt-primary'}>
                        {label}
                    </Text>
                </div>
            )}
            {leftIcon && <img className={css.left__icon} src={leftIcon} alt="input icon" />}
            <Dropdown
                disabled={disabled}
                options={options}
                onChange={handleChange}
                onFocus={handleFocus}
                // @ts-ignore
                value={value}
                placeholder={onlyShowPlaceholder ? placeholder : placeholder || options[0].label}
                controlClassName={`${css.select__control} ${
                    isOpen && isOptionSelected && css.select__control__is_open
                } ${customControl || ''} ${isFloatingLabel && value && css.select__placeholder__new_style__active}`}
                placeholderClassName={`${css.select__placeholder} ${leftIcon && css.select__placeholder__position} ${
                    customPlaceholder || ''
                } ${isFloatingLabel && css.select__placeholder__new_style} `}
                className={`${css.select} ${customSelect || ''}`}
                menuClassName={`${css.select__menu} ${customMenu || ''}`}
                arrowClassName={css.select__arrow}
                arrowClosed={arrowClosed}
                arrowOpen={arrowOpen}
                ref={menuRef}
            />
            {isFormikComponent && (
                <ErrorMessage name={name} render={(msg) => <InputErrorMessage name={name} messageCode={msg} />} />
            )}
        </div>
    );
};

export default Select;
