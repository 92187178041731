import React from 'react';

import Link from 'next/link';
import Collapsible from 'react-collapsible';

import Text from '@components/common/Text/Text';

import css from './FooterCollapsible.module.scss';

type CustomCollapsibleProps = {
    trigger: string;
    elementId: string;
    content: { row: string; link: string; icon?: string; openInNewTab: boolean }[];
};

const FooterCollapsible = ({ trigger, content, elementId }: CustomCollapsibleProps) => {
    return (
        <div>
            <Collapsible
                classParentString={css.collapsible}
                triggerClassName={css.trigger}
                triggerOpenedClassName={css.trigger__opened}
                contentInnerClassName={css.content__inner}
                contentOuterClassName={css.content__outer}
                triggerTagName={'div'}
                trigger={trigger}
                easing={'ease-in-out'}
                contentElementId={`collapsible-content-${elementId}`}
                triggerElementProps={{
                    id: `collapsible-trigger-${elementId}`,
                }}
            >
                {content.map((item) => (
                    <Link
                        key={item.row}
                        href={item.link}
                        className={css.content__inner}
                        target={item.openInNewTab ? '_blank' : ''}
                    >
                        <Text variant={'body_02'} color={'txt-secondary'}>
                            {item.row}
                            {item.icon && <img className={css.content__icon} src={item.icon} alt="link" />}
                        </Text>
                    </Link>
                ))}
            </Collapsible>
        </div>
    );
};

export default FooterCollapsible;
